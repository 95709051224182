<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs" :navigableDetail="false"></pui-datatable>
		<helptable-modals :modelName="modelName"></helptable-modals>
	</div>
</template>

<script>
export default {
	name: 'helptable-grid',
	components: {},
	data() {
		return {
			publicPath: process.env.BASE_URL,
			modelName: 'helptable',
			modelColumnDefs: {
				resourcelink: {
					render: (data, type, row) => {
						const isPdfFile =
							row.resourcelink === 'CSV Import Guide' ||
							row.resourcelink === 'Example of submitting Situation Report' ||
							row.resourcelink == 'Example of submitting Pollution Report' ||
							row.resourcelink == 'NMSW - User Guide' ||
							row.resourcelink == '4_01_032_CSV_Using_Istruction_Rev_1' ||
							row.resourcelink == 'NMSW - User manual - GEO_MP Validation' ||
							row.resourcelink == '4-01-033 NMSW_working Guideline_ENG_Rev_1' ||
							row.resourcelink == '4-01-033 NMSW_working Guideline_ GEO_Rev_1' ||
							row.resourcelink == '4-01-034 NMSW_User authentication_ENG_Rev_1' ||
							row.resourcelink == '4-01-034 GNMSW_User_authentication_GEO_Rev_1' ||
							row.resourcelink == 'CSV_Link_ENG' ||
							row.resourcelink == 'CSV_ლინკი_GEO';

						const isXlsmFile =
							row.resourcelink === 'FAL Form 2-CargoDeclaration' ||
							row.resourcelink === 'FAL Form 3-ShipsStoresDeclaration' ||
							row.resourcelink === 'FAL Form 4-CrewsEffectsDeclaration' ||
							row.resourcelink === 'FAL Form 5-CrewList' ||
							row.resourcelink === 'FAL Form 5-DriverList' ||
							row.resourcelink === 'FAL Form 6-VehiclesDetails' ||
							row.resourcelink === 'FAL Form 7-DangerousGoodsManifest';

						const url = `${this.publicPath}${row.resourcelink}${isPdfFile ? '.pdf' : '.csv'}`;
						const title = `${row.resourcelink}${isPdfFile ? '.pdf' : '.csv'}`;

						const url2 = `${this.publicPath}${row.resourcelink}${isXlsmFile ? '.xlsm' : '.csv'}`;
						const title2 = `${row.resourcelink}${isXlsmFile ? '.xlsm' : '.csv'}`;

						if (isXlsmFile) {
							return `<a href='${url2}' title='${title2}' target="_blank">
								<i aria-hidden="true" class="v-icon notranslate fa-kit fa-line-document-view theme--light" style="font-size: 25px; color:#A1A1A1"></i>
								</a>`;
						} else {
							return `<a href='${url}' title='${title}' target="_blank">
								<i aria-hidden="true" class="v-icon notranslate fa-kit fa-line-document-view theme--light" style="font-size: 25px; color:#A1A1A1"></i>
								</a>`;
						}
					}
				}
			}
		};
	},
	methods: {
		enviar() {}
	},
	mounted() {}
};
</script>
